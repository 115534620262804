@import 'shared';

.RichText {
  --left-list-spacing: #{px(30)};

  @include container;

  a {
    color: $darker-gold;
    text-decoration: underline;
    text-underline-position: under;
  }

  b,
  strong {
    font-weight: 700;
  }

  &.ul,
  &.ol {
    & li {
      position: relative;
      margin-left: px(30);
      margin-bottom: px(15);
    }
  }

  &.ul,
  ul {
    // NOTE: Special case for tiny typography
    &:global(.is-style-tiny) {
      > li {
        &::before {
          top: px(3);
        }
      }
    }

    > li {
      &::before {
        content: '';
        position: absolute;
        border-radius: 50%;
        display: block;
        @include box(px(4));
        background-color: $darker-gold;
        top: calc(0.5em + 2px);
        left: px(-15);
      }
    }
  }

  &.ol,
  ol {
    counter-reset: item;

    > li {
      &::before {
        position: absolute;
        display: block;
        content: counter(item) '. ';
        counter-increment: item;
        top: 0;
        left: px(-30 + 4);
        color: $darker-gold;
      }
    }
  }

  // NOTE: Nested List treatment
  ol,
  ul {
    margin-left: px(30);
    margin-top: px(20);
  }
}
