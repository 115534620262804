@import 'shared';

.Column {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &.alignCenter {
    justify-content: center;
  }

  &.alignBottom {
    justify-content: flex-end;
  }
}
