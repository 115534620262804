@import 'shared';

.BackButton {
  --bg-svg: #{$white};
  --color-svg: #{$black};
  --border-svg: #{$black};

  display: flex;
  gap: px(16);
  align-items: center;
  cursor: pointer;
  @include typography-label;

  &.withBorder {
    --border-svg: #{$dark-grey};

    border-radius: px(40);
    border: 1px solid $gold;
    padding: px(7) px(10) px(7) px(20);
    gap: px(10);

    @include media-tablet {
      gap: px(12);
      padding: px(11) px(10) px(11) px(20);
    }

    @include media-desktop {
      gap: px(16);
      padding: px(11) px(16) px(11) px(24);
    }

    .circle {
      @include box(px(24));
    }

    .svg {
      @include box(px(10));
    }
  }

  .circle {
    @include box(px(40));
    background-color: var(--bg-svg);
    border: 1px solid var(--border-svg);
    @include flex-center;
    border-radius: 50%;

    transition-property: background-color, border-color;
    transition-duration: $default-duration;
    transition-timing-function: $default-ease;
  }

  .svg {
    transition: color $default-duration $default-ease;
    @include box(px(15));
    color: var(--color-svg);
    transform: rotate(180deg);
  }

  &:hover {
    --bg-svg: #{$gold};
    --color-svg: #{$white};
    --border-svg: #{transparent};
  }
}
