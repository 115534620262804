@import 'shared';

.Columns {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: px(16);

  &.columns-1 {
    display: grid;
    grid-template-columns: 1fr;
  }

  &.columns-2 {
    @include media-tablet {
      display: grid;
      grid-template-columns: minmax(0, 0.7fr) minmax(0, 1fr);
    }

    &:global(.shifted-1) {
      @include media-tablet {
        grid-template-columns: minmax(0, 1fr) minmax(0, 2fr);
      }
    }
  }

  &.alignCenter {
    align-items: center;
  }

  &.alignBottom {
    align-items: end;
  }

  @include general-spacing-styles;
  @include general-max-width;
}
