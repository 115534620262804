@import '../../styles/shared.scss';

.NotFound {
  min-height: vh(50);

  .title {
    color: $gold;
    margin-bottom: px(35);

    @include media-tablet {
      margin-bottom: px(50);
    }
  }

  .content {
    @include typography-paragraph-2;
    margin-bottom: px(20);

    @include media-tablet {
      margin-bottom: px(35);
    }
  }
}
